import React, { useEffect } from 'react';

function Lightbox(props) {
  useEffect(() => {
    document.body.classList.add('lightbox-active')
    return () => document.body.classList.remove('lightbox-active')
  })

  useEffect(() => {
    // Close Lightbox on ESC
    function handleKey(e) {
      if (e.key === "Escape") {
        props.onEscape(e)
      }
    }
    document.addEventListener('keydown', handleKey);
    return () => document.removeEventListener('keydown', handleKey);
  })

  return (
    <div className="Lightbox" onClick={props.onClick}>
      {props.children}
    </div>
  )
}

export default Lightbox;