import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import Space from './Space'
import Landing from './Landing';
import Nanoblits from './Nanoblits';
import Product from './products/Product'
import ErrorScreen from './ErrorScreen';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
        
          <Route exact path='/nanoblits'>
            <Nanoblits />
          </Route>
          
          <Route exact path='/shop/product'>
            <Product />
          </Route>
          
          <Route exact path='/'>
            <Landing />
          </Route>
          <Route exact path='/play/:id'>
            <ErrorScreen>
              <span>Invitation to <br /><a href={window.location.pathname}>{window.location.pathname}</a></span>
            </ErrorScreen>
          </Route>
          <Route exact path='/:id' render={(props) => (
            <Space {...props} />
          )} />
        </Switch>
      </div>
    </Router>

  );
}

export default App;
