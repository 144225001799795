import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { BASE_API_URL } from '../helpers/constants';
import PhotoBlock from './PhotoBlock';
import VideoBlock from './VideoBlock';
import TextBlock from './TextBlock';

function Space(props) {
  let { id } = useParams(); // space id, extracted from the url
  const [posts, setPosts] = useState([])
  const [spaceWidth, setSpaceWidth] = useState(window.innerWidth)
  const history = useHistory()
  
  const spaceEndpoint = (id) => (
    (process.env.NODE_ENV === 'development')
      ? `/v1/spaces/${id}`
      : `${BASE_API_URL}/web/space/${id}`
  ) 
   
  useEffect(() => {
    fetch(spaceEndpoint(id))
      .then(res => res.json())
      .then(json => setPosts(json.posts))
      .catch(err => {
        console.log(err)
        history.push('/') // redirect
      })
  }, [id, history])

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight)
  }, [posts])

  useEffect(() => {
    // Update spaceWidth on resize
    function handleResize() {      
      setSpaceWidth(window.innerWidth)
    }
    handleResize()
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [id])

  const blocks = posts.map((post) => {
    if (post.photo) 
      return <PhotoBlock key={post.id} id={post.id} photo={post.photo} sizeType={post.sizeType} spaceWidth={spaceWidth} />
    else if (post.text) 
      return <TextBlock  key={post.id} id={post.id} text={post.text} sizeType={post.sizeType} />
    else if (post.video) 
      return <VideoBlock key={post.id} id={post.id} video={post.video} sizeType={post.sizeType} spaceWidth={spaceWidth} />;
    else return null;
  })

  return (
    <div className="Space" id={id}>
      {blocks}
    </div>
  );
}

export default Space;
