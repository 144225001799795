import React from "react";
import parseText from "../helpers/parseText";

function TextBlock(props) {
  const sizeClass = `size-${props.sizeType}`
  const fontClass = `font-${props.text.font}`
  const uppercaseClass = props.text.uppercase ? 'uppercase' : ''

  return (
    <div className={`TextBlock block ${sizeClass} ${fontClass} ${uppercaseClass}`}>
      {parseText(props.text.text)}
    </div>
  )
}

export default TextBlock