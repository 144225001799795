import React, { useEffect } from 'react'
import './Landing.scss';
import SmoothScroll from 'smooth-scroll'

function Landing() {
  useEffect(() => {
    document.body.classList.add('black')
    return () => document.body.classList.remove('black')
  }, []); // <-- empty dependency array so runs on mount only

  return (
    <div className="Landing">
      
      <div class="whitebg">
        
        <p class="x300 blue w100">
          PUT<br />
          BLOCKS<br />
        </p>
        <p class="x200 silver w100">
          VISUALS<br />
          WORDS<br />
          SOUNDS<br />
          LINKS<br />
        </p>
        
        <p class="x300 red w100">
          INTO<br />
          SPACES<br />
        </p>
        <p class="x200 pink w100">
          BY YOURSELF<br />
          TOGETHER<br />
          PRIVATE<br />
          PUBLIC<br />
        </p>
          
        <hr class="h50" />
        
        <div class="example">
          <img class="icon" src="files/0/1.svg" />
          <img class="screen" src="files/2/1.jpg" />
        </div>
	
	      <hr class="h25" />
	      
	      <div class="example">
          <img class="icon" src="files/0/2.svg" />
          <img class="screen" src="files/2/2.jpg" />
        </div>
	      
	      <hr class="h25" />
	      
	      <div class="example">
          <img class="icon" src="files/0/3.svg" />
          <img class="screen" src="files/2/3.jpg" />
        </div>
	      
	      <hr class="h25" />
	      
	      <div class="example">
          <img class="icon" src="files/0/4.svg" />
          <img class="screen" src="files/2/4.jpg" />
        </div>
	      
	      <hr class="h25" />
	      
	      <div class="example">
          <img class="icon" src="files/0/5.svg" />
          <img class="screen" src="files/2/5.jpg" />
        </div>
	      
	      <hr class="h25" />
	      
	      <div class="example">
          <img class="icon" src="files/0/6.svg" />
          <img class="screen" src="files/2/6.jpg" />
        </div>
	      
	      <hr class="h25" />
	      
	      <div class="example">
          <img class="icon" src="files/0/7.svg" />
          <img class="screen" src="files/2/7.jpg" />
        </div>
	      
	      <hr class="h25" />
	      
	      <div class="example">
          <img class="icon" src="files/0/8.svg" />
          <img class="screen" src="files/2/8.jpg" />
        </div>
	      
	      <hr class="h25" />
	      
	      <div class="example">
          <img class="icon" src="files/0/9.svg" />
          <img class="screen" src="files/2/9.jpg" />
        </div>
	      
	      <hr class="h25" />
	      
	      <div class="example">
          <img class="icon" src="files/0/10.svg" />
          <img class="screen" src="files/2/10.jpg" />
        </div>
	      
	      <hr class="h25" />
	      
	      <div class="example">
          <img class="icon" src="files/0/11.svg" />
          <img class="screen" src="files/2/11.jpg" />
        </div>
        
        <hr class="h50" />
        
        <div class="shot">
  	      <div class="shots">
    		    <img class="shot" src="files/1/1.png" />
    		    <img class="shot" src="files/1/2.png" />
    		    <img class="shot" src="files/1/3.png" />
    		    <img class="shot" src="files/1/4.png" />
  		      <img class="shot" src="files/1/5.png" />
  		      <img class="shot" src="files/1/6.png" />
  		      <img class="shot" src="files/1/7.png" />
  		      <img class="shot" src="files/1/8.png" />
  	      </div>
	      </div>
        
        <hr class="h50" />
        
        <p class="x250 silver c">
          7 USD<br />
          MONTHLY
        </p>
        <p class="x200 tone c">
          100 FREE<br />
          BLOCKS
        </p>
        
        <hr class="h50" />
        
      </div>
      
      <a class="button beta x300 tap" href="https://apps.apple.com/us/app/id1405150217" target="_blank">
        <span class="apple">A</span>BETA
      </a>
      
    </div>
  )
}

export default Landing