import React, { useEffect } from "react"
import { BASE_ASSET_URL } from "../helpers/constants";
import resizeResolutionTo from '../helpers/resizeResolutionTo';
import scaleResolutionTo from "../helpers/scaleResolutionTo";

function FrameAnimation(props) {
  const visibleFrameClassName = "visible-frame"
  const duration = props.video.duration * 1000
  const frameOffsets = [0, Math.floor(duration / 2), duration - 100] // -100ms for end-frame to fix the error when providing full duration
  const resizedTo = resizeResolutionTo(props.video.resolution, props.sizeType)
  const scaledTo = (props.sizeType === 0) ? resizedTo : scaleResolutionTo(resizedTo, props.spaceWidth)
  const frameUrls = frameOffsets.map(offset => `${BASE_ASSET_URL}/processed/${props.video.resourceId}.${props.video.format}?width=${resizedTo.width}&timeOffset=${offset}&extension=jpg&originalBucket=mine-media&processedBucket=mine-media-processed`)

  useEffect(() => {
    // frame animation
    const frames = document.getElementById(props.video.resourceId).children
    let currentFrameIndex = 0
    const nextFrameIndex = () => (currentFrameIndex + 1 === frames.length) ? 0 : currentFrameIndex + 1
    const intervalId = setInterval(() => {
      frames[currentFrameIndex].classList.remove(visibleFrameClassName)
      frames[nextFrameIndex()].classList.add(visibleFrameClassName)
      currentFrameIndex = nextFrameIndex()
    }, 1000)
    return function cleanup() {
      clearInterval(intervalId)
    }
  }, [props.video.resourceId])

  return (
    <div className="FrameAnimation" id={props.video.resourceId}>
      {frameUrls.map((url, index) => (
        <img src={url} key={index} className={(index === 0) ? visibleFrameClassName : ""} width={scaledTo.width} height={scaledTo.height} loading="lazy" alt="" />
      ))}
    </div>
  )
}

export default FrameAnimation