import { useEffect } from 'react';
import ReactDOM from "react-dom";

function Modal(props) {
  const modalRoot = document.getElementById('modal-root')
  const modalElement = document.createElement('div')
  modalElement.classList.add('Modal')

  useEffect(() => {
    modalRoot.appendChild(modalElement)
    return () => modalRoot.removeChild(modalElement)
  })

  return ReactDOM.createPortal(
    props.children,
    modalElement
  )
}

export default Modal;