import React, { useEffect } from 'react';

const Helmet = () => {
  useEffect(() => {
    document.body.classList.add('shop')
    return () => document.body.classList.remove('shop')
  })
  
  return (
    <div className="Landing">
      
      <div className="vert">
      
      <img className="full" src="../images/shop/helmet.jpg" />
      
      <p className="details">
        HELMET<br />
        14X10 INCHES<br />
        SOFT PASTEL<br />
        OIL STICK<br />
        PAPER
      </p>
      
      <a href="https://buy.stripe.com/aEU7uL8C0amrdKEaEF"><div className="buy"></div></a>
    
      </div>
      
    </div>
  )
}

export default Helmet;