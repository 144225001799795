import React, { useEffect } from 'react';

const Nanoblits = () => {
  useEffect(() => {
    document.body.classList.add('black')
    return () => document.body.classList.remove('black')
  })
  
  return (
    <div className="Landing">
      
      <div class="intro">
        
        <p class="x300 blits">
          100<br />
          ORIGINAL<br />
          BLITS<br />
          <span class="smaller">
            4X<br />
            SMALLER<br />
          </span>
          BECAUSE<br />
          WE<br />
          LOVE <a class="blits inline" href="https://www.blitmap.com" target="_blank">THE<br />
            BLITMAPS!
          </a>
        </p>
      
      </div>
      
      <hr class="h50" />

      <div class="nano">
        
        <div class="blit">
          <img class="blit" src="files/blits/0.png" />
          <p class="blit n0">GENESIS</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/1.png" />
          <p class="blit n1">ROSE</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/2.png" />
          <p class="blit n2">BOOMBOX</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/3.png" />
          <p class="blit n3">NODE</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/4.png" />
          <p class="blit n4">PSYCHIC</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/5.png" />
          <p class="blit n5">IKURA</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/6.png" />
          <p class="blit n6">EDITION</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/7.png" />
          <p class="blit n7">SELECTED</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/8.png" />
          <p class="blit n8">KRAKEN</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/9.png" />
          <p class="blit n9">TOTALLY</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/10.png" />
          <p class="blit n10">LOONEY</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/11.png" />
          <p class="blit n11">ATLANTIS</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/12.png" />
          <p class="blit n12">OTORO</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/13.png" />
          <p class="blit n13">JUPITER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/14.png" />
          <p class="blit n14">OVERLOADED</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/15.png" />
          <p class="blit n15">MASK</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/16.png" />
          <p class="blit n16">VANILLA</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/17.png" />
          <p class="blit n17">TAMAGO</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/18.png" />
          <p class="blit n18">THE CAT</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/19.png" />
          <p class="blit n19">EBI</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/20.png" />
          <p class="blit n20">ERUPTION</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/21.png" />
          <p class="blit n21">NIGHT MOON</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/22.png" />
          <p class="blit n22">PROCESSING</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/23.png" />
          <p class="blit n23">THE SUN</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/24.png" />
          <p class="blit n24">HILLSIDE</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/25.png" />
          <p class="blit n25">EVER LOVIN</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/26.png" />
          <p class="blit n26">CHEESE</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/27.png" />
          <p class="blit n27">SCRAMBLE</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/28.png" />
          <p class="blit n28">MODERATOR</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/29.png" />
          <p class="blit n29">SWORDY</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/30.png" />
          <p class="blit n30">HEALTREEY</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/31.png" />
          <p class="blit n31">MAGEGLOVEY</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/32.png" />
          <p class="blit n32">FEATHERY</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/33.png" />
          <p class="blit n33">TEDDY</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/34.png" />
          <p class="blit n34">CLOUDY</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/35.png" />
          <p class="blit n35">CANDY</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/36.png" />
          <p class="blit n36">CHESSBIRDY</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/37.png" />
          <p class="blit n37">ANGRYBIRBY</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/38.png" />
          <p class="blit n38">BURGERY</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/39.png" />
          <p class="blit n39">PERIWINKLE</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/40.png" />
          <p class="blit n40">SPROUT</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/41.png" />
          <p class="blit n41">AMAI</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/42.png" />
          <p class="blit n42">BLIT</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/43.png" />
          <p class="blit n43">RUNE</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/44.png" />
          <p class="blit n44">VIBE</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/45.png" />
          <p class="blit n45">RIDE</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/46.png" />
          <p class="blit n46">ANDREI</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/47.png" />
          <p class="blit n47">SOFT BOY</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/48.png" />
          <p class="blit n48">PIZZA TIME</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/49.png" />
          <p class="blit n49">HOT DAMN</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/50.png" />
          <p class="blit n50">EVERYTHING</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/51.png" />
          <p class="blit n51">ILLUMINATE</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/52.png" />
          <p class="blit n52">SIX MONTHS</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/53.png" />
          <p class="blit n53">MANA</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/54.png" />
          <p class="blit n54">NUDE</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/55.png" />
          <p class="blit n55">KABUTO</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/56.png" />
          <p class="blit n56">MORI</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/57.png" />
          <p class="blit n57">HAND</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/58.png" />
          <p class="blit n58">GATO</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/59.png" />
          <p class="blit n59">EAR</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/60.png" />
          <p class="blit n60">EYE</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/61.png" />
          <p class="blit n61">JESS</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/62.png" />
          <p class="blit n62">LAY OFF ME</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/63.png" />
          <p class="blit n63">SAY WHAT</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/64.png" />
          <p class="blit n64">COOL CAT</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/65.png" />
          <p class="blit n65">EGGPLANT</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/66.png" />
          <p class="blit n66">SASQUATCH</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/67.png" />
          <p class="blit n67">MUSHROOM</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/68.png" />
          <p class="blit n68">DUNCE</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/69.png" />
          <p class="blit n69">SOFT</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/70.png" />
          <p class="blit n70">END</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/71.png" />
          <p class="blit n71">EMBRYO</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/72.png" />
          <p class="blit n72">WATCHING</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/73.png" />
          <p class="blit n73">NEWS</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/74.png" />
          <p class="blit n74">CAFFEINATE</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/75.png" />
          <p class="blit n75">COMMUNION</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/76.png" />
          <p class="blit n76">DIN-DIN</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/77.png" />
          <p class="blit n77">WARM VIBE</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/78.png" />
          <p class="blit n78">THE GUY</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/79.png" />
          <p class="blit n79">RESET</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/80.png" />
          <p class="blit n80">KEY</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/81.png" />
          <p class="blit n81">GLIMPSE</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/82.png" />
          <p class="blit n82">BOYSDOCRY</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/83.png" />
          <p class="blit n83">WAVE</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/84.png" />
          <p class="blit n84">LOGO</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/85.png" />
          <p class="blit n85">MELT MAN</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/86.png" />
          <p class="blit n86">CHERRYBOMB</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/87.png" />
          <p class="blit n87">MELONY</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/88.png" />
          <p class="blit n88">FISHY</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/89.png" />
          <p class="blit n89">WATCHER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/90.png" />
          <p class="blit n90">GLITCHO</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/91.png" />
          <p class="blit n91">CITY</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/92.png" />
          <p class="blit n92">EXE</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/93.png" />
          <p class="blit n93">SOCK CLOUD</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/94.png" />
          <p class="blit n94">GATE</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/95.png" />
          <p class="blit n95">OPTIMIST</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/96.png" />
          <p class="blit n96">PALE TOWER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/97.png" />
          <p class="blit n97">DRINK ME</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/98.png" />
          <p class="blit n98">CHAOS FLOW</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/99.png" />
          <p class="blit n99">LOST REALM</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/loot.png" />
          <p class="blit n100">LOOT</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/sup.png" />
          <p class="blit n101">SUP</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/blitnaut.png" />
          <p class="blit n102">BLITNAUT</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/corruption.png" />
          <p class="blit n103">CORRUPTION</p>
        </div>
      
      </div>
      
      <div class="og">
      
        <div class="blit">
          <img class="blit" src="files/blits/og/0.png" />
          <p class="blit n0">DOM</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/1.png" />
          <p class="blit n1">DOM</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/2.png" />
          <p class="blit n2">DOM</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/3.png" />
          <p class="blit n3">DOM</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/4.png" />
          <p class="blit n4">VEENUS</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/5.png" />
          <p class="blit n5">VEENUS</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/6.png" />
          <p class="blit n6">BIGPAPAP</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/7.png" />
          <p class="blit n7">BIGPAPAP</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/8.png" />
          <p class="blit n8">VEENUS</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/9.png" />
          <p class="blit n9">BIGPAPAP</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/10.png" />
          <p class="blit n10">VEENUS</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/11.png" />
          <p class="blit n11">VEENUS</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/12.png" />
          <p class="blit n12">VEENUS</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/13.png" />
          <p class="blit n13">BIGPAPAP</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/14.png" />
          <p class="blit n14">BIGPAPAP</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/15.png" />
          <p class="blit n15">DOM</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/16.png" />
          <p class="blit n16">BIGPAPAP</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/17.png" />
          <p class="blit n17">VEENUS</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/18.png" />
          <p class="blit n18">BIGPAPAP</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/19.png" />
          <p class="blit n19">VEENUS</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/20.png" />
          <p class="blit n20">VEENUS</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/21.png" />
          <p class="blit n21">BIGPAPAP</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/22.png" />
          <p class="blit n22">BIGPAPAP</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/23.png" />
          <p class="blit n23">VEENUS</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/24.png" />
          <p class="blit n24">VEENUS</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/25.png" />
          <p class="blit n25">BIGPAPAP</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/26.png" />
          <p class="blit n26">VEENUS</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/27.png" />
          <p class="blit n27">BIGPAPAP</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/28.png" />
          <p class="blit n28">BIGPAPAP</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/29.png" />
          <p class="blit n29">NUMO</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/30.png" />
          <p class="blit n30">NUMO</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/31.png" />
          <p class="blit n31">NUMO</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/32.png" />
          <p class="blit n32">NUMO</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/33.png" />
          <p class="blit n33">NUMO</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/34.png" />
          <p class="blit n34">NUMO</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/35.png" />
          <p class="blit n35">NUMO</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/36.png" />
          <p class="blit n36">NUMO</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/37.png" />
          <p class="blit n37">NUMO</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/38.png" />
          <p class="blit n38">NUMO</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/39.png" />
          <p class="blit n39">BOYPRISON</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/40.png" />
          <p class="blit n40">BOYPRISON</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/41.png" />
          <p class="blit n41">BOYPRISON</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/42.png" />
          <p class="blit n42">DOM</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/43.png" />
          <p class="blit n43">DOM</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/44.png" />
          <p class="blit n44">ZOD</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/45.png" />
          <p class="blit n45">ZOD</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/46.png" />
          <p class="blit n46">OTHER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/47.png" />
          <p class="blit n47">HIGHLEYVARLET</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/48.png" />
          <p class="blit n48">HIGHLEYVARLET</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/49.png" />
          <p class="blit n49">HIGHLEYVARLET</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/50.png" />
          <p class="blit n50">HIGHLEYVARLET</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/51.png" />
          <p class="blit n51">BRAINDRAIND</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/52.png" />
          <p class="blit n52">WORM</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/53.png" />
          <p class="blit n53">CERESSTATION</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/54.png" />
          <p class="blit n54">OTHER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/55.png" />
          <p class="blit n55">ZOD</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/56.png" />
          <p class="blit n56">OTHER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/57.png" />
          <p class="blit n57">OTHER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/58.png" />
          <p class="blit n58">JSTN</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/59.png" />
          <p class="blit n59">OTHER</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/60.png" />
          <p class="blit n60">OTHER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/61.png" />
          <p class="blit n61">OTHER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/62.png" />
          <p class="blit n62">BRAINDRAIND</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/63.png" />
          <p class="blit n63">BRAINDRAIND</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/64.png" />
          <p class="blit n64">BRAINDRAIND</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/65.png" />
          <p class="blit n65">BRAINDRAIND</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/66.png" />
          <p class="blit n66">BRAINDRAIND</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/67.png" />
          <p class="blit n67">BRAINDRAIND</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/68.png" />
          <p class="blit n68">OTHER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/69.png" />
          <p class="blit n69">OTHER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/70.png" />
          <p class="blit n70">OTHER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/71.png" />
          <p class="blit n71">OTHER</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/72.png" />
          <p class="blit n72">OTHER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/73.png" />
          <p class="blit n73">OTHER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/74.png" />
          <p class="blit n74">BRAINDRAIND</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/75.png" />
          <p class="blit n75">BRAINDRAIND</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/76.png" />
          <p class="blit n76">BRAINDRAIND</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/77.png" />
          <p class="blit n77">BRAINDRAIND</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/78.png" />
          <p class="blit n78">SPACEDOCTOR</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/79.png" />
          <p class="blit n79">OTHER</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/80.png" />
          <p class="blit n80">OTHER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/81.png" />
          <p class="blit n81">DOM</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/82.png" />
          <p class="blit n82">HIPCITYREG</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/83.png" />
          <p class="blit n83">ZOD</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/84.png" />
          <p class="blit n84">DOM</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/85.png" />
          <p class="blit n85">SPACEDOCTOR</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/86.png" />
          <p class="blit n86">YI</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/87.png" />
          <p class="blit n87">NUMO</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/88.png" />
          <p class="blit n88">NUMO</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/89.png" />
          <p class="blit n89">DOM</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/90.png" />
          <p class="blit n90">DOM</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/91.png" />
          <p class="blit n91">DOM</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/92.png" />
          <p class="blit n92">DOM</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/93.png" />
          <p class="blit n93">WORM</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/94.png" />
          <p class="blit n94">DOM</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/95.png" />
          <p class="blit n95">ASKYWLKR</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/og/96.png" />
          <p class="blit n96">THEMOONLADDER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/97.png" />
          <p class="blit n97">ASKYWLKR</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/98.png" />
          <p class="blit n98">THEMOONLADDER</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/og/99.png" />
          <p class="blit n99">THEMOONLADDER</p>
        </div>
        
        <div class="blit">
          <img class="blit" src="files/blits/loot.png" />
          <p class="blit n100">LOOT</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/sup.png" />
          <p class="blit n101">SUP</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/blitnaut.png" />
          <p class="blit n102">BLITNAUT</p>
        </div>
        <div class="blit">
          <img class="blit" src="files/blits/corruption.png" />
          <p class="blit n103">CORRUPTION</p>
        </div>
      
      </div>

      <hr class="h50" />
      
      <a class="button collect x300 tap" href="https://opensea.io/collection/nanoblits" target="_blank">
        COLLECT
      </a>
      
    </div>
  )
}

export default Nanoblits;