import React from "react";
const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const linkRegEx = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g
const linkStartRegEx = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)/g
const httpRegEx = /^(http)/g

/*
 * returns an array of JSX elements
 */
function parseText(text) {
  if (text === "") return [<span key="0">&nbsp;</span>];

  var paragraph = {
    elements: [],
    textBuffer: [],
    keyCount: 0,
    addWord(word) {
      this.textBuffer.push(word)
    },
    addLink(link) {
      this.renderTextBuffer()
      const nakedLink = link.replace(linkStartRegEx, '')
      const fullLink = link.match(httpRegEx) ? link : `https://${link}`
      this.elements.push(<a href={fullLink} key={this.nextKey()} target='_blank' rel='noopener noreferrer'>{nakedLink}</a>)
    },
    addEmail(email) {
      this.renderTextBuffer()
      const emailLink = `mailto:${email}`
      this.elements.push(<a href={emailLink} key={this.nextKey()}>{email}</a>)
    },
    addSpace() {
      this.textBuffer.push(' ')
    },
    addLineBreak() {
      this.renderTextBuffer()
      this.elements.push(<br key={this.nextKey()}/>)
    },
    renderTextBuffer() {
      if (this.textBuffer.length > 0) {
        this.elements.push(<span key={this.nextKey()}>{this.textBuffer.join('')}</span>)
        this.textBuffer = []
      }
    },
    nextKey() {
      return this.keyCount++
    },
    finalize() {
      this.renderTextBuffer()
    }
  }

  let lines = text.split('\n')
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i]
    let words = line.split(' ')
    for (let j = 0; j < words.length; j++) {
      const word = words[j];
      if (word.toLowerCase().match(linkRegEx)) {
        paragraph.addLink(word) 
      } else if (word.match(emailRegEx)) {
        paragraph.addEmail(word)
      } else {
        paragraph.addWord(word)
      }
      if (j < words.length - 1) {
        paragraph.addSpace()
      }
    }
    if (i < lines.length - 1) {
      paragraph.addLineBreak()
    }
  }

  paragraph.finalize()
  return paragraph.elements
}

export default parseText