import React, { useState, useRef } from "react"
import { BASE_ASSET_URL } from "../helpers/constants";
import Modal from "./Modal";
import Lightbox from "./Lightbox";
import FrameAnimation from "./FrameAnimation";
import resizeResolutionTo from "../helpers/resizeResolutionTo";

function VideoBlock(props) {
  const videoRef = useRef(null)
  const [displayLightbox, setDisplayLightbox] = useState(false)
  const videoUrl = `${BASE_ASSET_URL}/${props.video.resourceId}.${props.video.format}`
  // poster url
  // We use resizedTo to create posterUrl identical to the first frame from FrameAnimation
  // so we can re-use already downloaded image from the cache
  const resizedTo = resizeResolutionTo(props.video.resolution, props.sizeType) 
  const posterUrl = `${BASE_ASSET_URL}/processed/${props.video.resourceId}.${props.video.format}?width=${resizedTo.width}&timeOffset=0&extension=jpg&originalBucket=mine-media&processedBucket=mine-media-processed`

  const toggleLightbox = e => {
    if (e.target.tagName !== 'VIDEO') setDisplayLightbox(!displayLightbox)
    if (videoRef.current !== null && e.target.tagName === 'VIDEO') {

      const toolbarHeight = 50; 
      const videoElement = videoRef.current.getBoundingClientRect()
      const y = e.clientY - videoElement.top

      if (y >= videoElement.height - toolbarHeight || y <= toolbarHeight) {
        return
      } else setDisplayLightbox(!displayLightbox)
    }

  }

  return (
    <div className={`VideoBlock block size-${props.sizeType}`} onClick={(e) => toggleLightbox(e)}>
      <div className="make-it-square">
        <FrameAnimation video={props.video} sizeType={props.sizeType} spaceWidth={props.spaceWidth} />
      </div>
      {displayLightbox &&
        <Modal>
          <Lightbox onEscape={toggleLightbox}>
              <video ref={videoRef} src={videoUrl} poster={posterUrl} preload="auto" playsInline controls muted autoPlay loop />  
          </Lightbox>
        </Modal>
      }
    </div>
  )
}

export default VideoBlock