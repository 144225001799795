import React, { useState } from 'react';
import Modal from "./Modal";
import Lightbox from "./Lightbox";
import { BASE_ASSET_URL } from "../helpers/constants";
import resizeResolutionTo from '../helpers/resizeResolutionTo';
import scaleResolutionTo from '../helpers/scaleResolutionTo';

function PhotoBlock(props) {
  const [displayLightbox, setDisplayLightbox] = useState(false)
  const resizedTo = resizeResolutionTo(props.photo.resolution, props.sizeType)
  const scaledTo = (props.sizeType === 0) ? resizedTo : scaleResolutionTo(resizedTo, props.spaceWidth)
  const resizedImgUrl = `${BASE_ASSET_URL}/processed/${props.photo.resourceId}.${props.photo.format}?width=${resizedTo.width}&extension=jpg&originalBucket=mine-media&processedBucket=mine-media-processed`
  const lightboxImgUrl = `${BASE_ASSET_URL}/${props.photo.resourceId}.${props.photo.format}`

  const toggleLightbox = () => {
    setDisplayLightbox(!displayLightbox)
  }

  return (
    <div className={`PhotoBlock block size-${props.sizeType}`} onClick={toggleLightbox} >
      <div className="make-it-square">
        <img src={resizedImgUrl} width={scaledTo.width} height={scaledTo.height} loading="lazy" alt="" />
      </div>
      {displayLightbox && 
      <Modal>
        <Lightbox onEscape={toggleLightbox}>
          <img src={lightboxImgUrl} alt="" />
        </Lightbox>
      </Modal>
      }
    </div>
  )
}

export default PhotoBlock