import { LARGE_PIC_SIZE, SMALL_PIC_SIZE } from "./constants"

function resizeResolutionTo(resolution, size) {
  // calculate scale factor
  let scaleFactor = 1
  if (size === 1) {
    // large size
    scaleFactor = Math.min(resolution.width, LARGE_PIC_SIZE) / resolution.width
  } else {
    // small size
    if (resolution.width > resolution.height) {
      // horizontal image, resize by height
      scaleFactor = Math.min(resolution.height, SMALL_PIC_SIZE) / resolution.height
    } else {
      scaleFactor = Math.min(resolution.width, SMALL_PIC_SIZE) / resolution.width
    }
  }
  return {
    width: Math.round(resolution.width * scaleFactor),
    height: Math.round(resolution.height * scaleFactor)
  }
}

export default resizeResolutionTo