import React from 'react';

function ErrorScreen(props) {
  return (
    <div className="ErrorScreen">
      <div className="TextBlock font-1 uppercase">{props.children}</div>
    </div>
  );
}

export default ErrorScreen;